<template>
  <div>
    <b-card
      v-if="account.crm === 'GSHEETS'"
      border-variant="secondary"
    >
      <b-row>
        <b-col md="9">
          <h4>Having problem with a current Spreadsheet?</h4>
          Create a new one and sync data there.
        </b-col>
        <b-col
          md="3"
        >
          <div class="new-spreadsheet-container">
            <b-button
              v-if="!isCreatingNewSpreadsheet"
              variant="outline-primary"
              @click="attachNewSpreadsheet"
            >
              Create new Spreadsheet
            </b-button>
            <b-button
              v-if="isCreatingNewSpreadsheet"
              class="new-spreadsheet-loader"
              variant="outline-primary"
              :disabled="true"
            >
              <b-spinner />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card border-variant="secondary">
      <b-row>
        <b-col md="9">
          <h4>Changing CRM?</h4>
          Get help to migrate from one CRM to other.
        </b-col>
        <b-col
          md="3"
          class="text-right"
        >
          <b-dropdown
            text="Pick CRM"
            variant="secondary"
          >
            <b-dropdown-item
              v-for="c in crms"
              :key="c"
              @click="sendMessage(c)"
            >
              {{ c }}
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal.modal-type-crm
            >
              Other
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-type-crm"
      title="What CRM are you switching to?"
      ok-title="Submit"
      ok-only
      :ok-disabled="!Boolean(crm)"
      centered
      @ok="sendMessage(crm)"
    >
      <b-form-input
        v-model="crm"
        placeholder="CRM"
        type="text"
        name="crm"
        required
      />
    </b-modal>

    <b-card
      v-if="!isFreeORTrialCompany()"
      :border-variant="account.licences && account.licences > 0 ? 'danger' : 'success'"
    >
      <b-row>
        <b-col md="9">
          <h4>Pause my Surfe subscription</h4>
          Stops the billing of your premium account. It can be resumed anytime.
        </b-col>
        <b-col
          md="3"
          class="d-flex justify-content-end align-items-center"
        >
          <b-button
            v-if="account.licences > 0 && !isLoading"
            :disabled="isTrial()"
            variant="outline-danger"
            @click="pauseSubVerification"
          >
            Pause Subscription
          </b-button>
          <b-button
            v-if=" !isLoading && (!account.licences || account.licences === 0)"
            variant="outline-success"
            @click="resumeSubscription"
          >
            Resume Subscription
          </b-button>
          <b-spinner v-if="isLoading" />
        </b-col>
      </b-row>
    </b-card>
    <VerificationCodeModal
      ref-id="pause-sub-modal-verification"
      @submit="showPauseSubModal()"
    />
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormInput, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import {
  authHeaders, sleep, isGodMode, hasValidVerificationToken,
} from '@/utils'
import { trackEvent } from '@core/utils/tracking'
import axios from 'axios'
import VerificationCodeModal from '@/views/billing/VerificationCodeModal.vue'

export default {
  components: {
    VerificationCodeModal,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      isCreatingNewSpreadsheet: false,
      crm: '',
      crms: ['HubSpot', 'Pipedrive', 'SalesForce', 'Copper'],
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
    }),
  },
  methods: {
    async attachNewSpreadsheet() {
      this.$bvModal.msgBoxConfirm('This action will create a new Spreadsheet and sync data there. Are you sure?', {
        title: 'Create new Spreadsheet',
        size: 'lg',
        okVariant: 'success',
        okTitle: 'Confirm',
        cancelTitle: 'Cancel',
        cancelVariant: 'outline-primary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async value => {
          if (!value) return
          try {
            this.isCreatingNewSpreadsheet = true
            const url = `${process.env.VUE_APP_CORE_API_URL}/auth/gsheets/spreadsheet?forceCreate=${true}`
            await axios.post(url, {}, authHeaders())
            await this.$store.dispatch('api/loadAccount')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'New Spreadsheet created',
                icon: 'SaveIcon',
                variant: 'success',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isCreatingNewSpreadsheet = false
          }
        })
    },
    resumeSubscription() {
      this.$router.push('/billing')
    },
    isTrial() {
      return !this.account.isPremium && !this.account.hasOEM && this.account.trialDaysLeft > 0
    },
    isFreeORTrialCompany() {
      return this.account.accountPlan === 'FREE' || this.account.accountPlan === 'TRIAL'
    },
    pauseSubVerification() {
      if (hasValidVerificationToken() || isGodMode()) {
        this.showPauseSubModal()
        return
      }
      this.$bvModal.show('pause-sub-modal-verification')
    },
    showPauseSubModal() {
      this.$bvModal
        .msgBoxConfirm('Your subscription will be paused, and your users will lose their premium licences. You won\'t be billed until you resume it', {
          title: 'Pause my Surfe subscription',
          size: 'lg',
          okVariant: 'danger',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (!value) return
          try {
            this.isLoading = true
            await this.$store.dispatch('api/updateSubscription', { quantity: 0 })

            await sleep(3000) // We have to wait for stripe to update the subs
            await this.$store.dispatch('api/loadAccount')
            await trackEvent({ type: 'dashboard-settings-subscription_toggled', props: { isPaused: true } })
            this.isLoading = false
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            throw error
          }
        })
    },
    async sendMessage(crm) {
      try {
        const supported = this.crms.includes(crm)
        await this.$store.dispatch('api/switchCRM', { crm, supported })
        this.crm = ''

        if (!supported) {
          await trackEvent({ type: 'dashboard-settings-change_crm_clicked', props: { crm: 'OTHER', other: crm.toUpperCase() } })
          return this.popupMessage('On our way', 'We will inform you once we support this CRM.')
        }

        await trackEvent({ type: 'dashboard-settings-change_crm_clicked', props: { crm: crm.toUpperCase() } })

        return this.popupMessage('', `Please visit <a href="https://intercom.help/surfe/en/articles/8367593-how-to-change-crms" target="_blank">our documentation</a> on how to reconnect Surfe to your CRM.<br/>
        For more information feel free to reach <a href="mailto:support@surfe.com">support@surfe.com</a>.`, 'info')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      }
    },
    popupMessage(title, html, icon = 'success') {
      this.$swal({
        title,
        html,
        icon,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped>
  .new-spreadsheet-container{
    min-width: 150px;
    max-width: 230px
  }
  .new-spreadsheet-loader {
    width: 100%;
  }
</style>
