<template>
  <b-modal
    v-if="refId !== ''"
    :id="refId"
    :ref="refId"
    size="md"
    bordered
    hide-header-close
    hide-header
    hide-footer
    no-fade
    title="Enter verification code that was sent to your email"
    centered
    @show="onOpen"
  >
    <h4 class="mb-3">
      Check your email
    </h4>
    <p class="subtitle">
      Please enter the code that was sent to <b>{{ account.email }}</b> to verify your identity.
      <br>
      If you haven't received it, check your spam folder.
    </p>
    <p class="verification-code">
      Verification code
    </p>
    <b-form-input
      v-model="code"
      class="verification-code-input"
      type="number"
    />
    <div class="d-flex justify-content-end mb-6">
      <b-button
        variant="white"
        class="mr-2"
        style="min-width: 100px;"
        @click="closeModal"
      >
        <span>Cancel</span>
      </b-button>
      <b-button
        variant="primary"
        :disabled="code === ''"
        @click="confirmVerificationCode"
      >
        <span>Submit</span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>

import {
  BModal, VBTooltip, BFormInput, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'

const { authHeaders, addToLocalStorageWithExpiration } = require('../../utils')

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BModal,
    BFormInput,
  },
  props: {
    refId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      code: '',
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
    }),
  },
  methods: {
    async onOpen() {
      const emailSent = localStorage.getItem('user-verification-email-sent')
      if (emailSent && emailSent.expires < new Date().getTime()) {
        return
      }
      await axios.post(`${process.env.VUE_APP_CORE_API_URL}/auth/otp/generate`, {}, authHeaders())
      addToLocalStorageWithExpiration('user-verification-email-sent', true, 2)
    },
    closeModal() {
      this.$refs[this.refId].hide()
    },
    async confirmVerificationCode() {
      try {
        const { data } = await axios.post(`${process.env.VUE_APP_CORE_API_URL}/auth/otp/verify`, { token: this.code },
          authHeaders())

        if (!data.token) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invalid verification code',
              icon: 'DangerIcon',
              variant: 'danger',
            },
          })
          return
        }

        localStorage.setItem('user-verification-token', data.token)
        this.$emit('submit')
        this.closeModal()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid verification code',
            icon: 'DangerIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped>
  .subtitle {
    color: #5A6C71;
    font-size: 14px;
    margin-bottom:49px;
  }

  .verification-code {
    font-size:14px
  }

  .verification-code-input {
    margin-bottom: 95px;
  }
</style>
