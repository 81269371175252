<template>
  <b-card class="p-2">
    <b-form-group
      label="Account Name"
      class="mb-3 font-small-2"
    >
      <b-form-input
        v-model="account.companyName"
        :disabled="!account.isAdmin"
        placeholder="Account Name"
        @change="updateAccount"
      />
    </b-form-group>

    <b-row
      v-for="key in Object.keys(getSettingsMap())"
      :key="key"
      class="my-8"
    >
      <b-col
        v-if="!isBehindFeatureFlag(key)"
        :md="settingsMap[key].colSize1"
      >
        <p class="mb-3 font-small-3 font-weight-bolder text-secondary text-accent-3">
          {{ settingsMap[key].title }}
        </p>
        <p class="mt-0 font-small-3 text-secondary text-darken-3">
          {{ settingsMap[key].description }}
        </p>
      </b-col>

      <b-col
        :md="settingsMap[key].colSize2"
        class="text-center"
      >
        <b-form-select
          v-if="settingsMap[key].type === 'select'"
          v-model="account.settings[key]"
          :disabled="!account.isAdmin"
          :options="settingsMap[key].options"
          class="mb-1"
          @input="updateAccount(key)"
        />
        <b-form-checkbox
          v-if="settingsMap[key].type === 'checkbox' && !isBehindFeatureFlag(key)"
          v-model="account.settings[key]"
          switch
          class="mb-1"
          :disabled="!account.isAdmin || account.settings[settingsMap[key].dependentOn] === false"
          @input="updateAccount(key)"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="account.salesEnablementTool && account.crm !== 'GSHEETS'"
      class="my-8"
    >
      <b-col md="8">
        <p class="mb-3 font-small-3 font-weight-bolder text-secondary text-accent-3">
          Read only Sales Enablement
        </p>
        <p class="mt-0 font-small-3 text-secondary text-darken-3">
          Display information coming from your connected Sales Enablement tool, without possibility to add profiles to a sequence.
        </p>
      </b-col>

      <b-col
        md="4"
        class="text-center"
      >
        <b-form-select
          v-model="account.settings.readOnlySalesEnablement"
          :disabled="!account.isAdmin"
          :options="optReadOnlySalesEnablement"
          class="mb-1"
          @input="updateAccount('readOnlySalesEnablement')"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="account.salesEnablementTool === 'SALESLOFT' && account.crm !== 'GSHEETS'"
      class="my-8"
    >
      <b-col md="8">
        <p class="mb-3 font-small-3 font-weight-bolder text-secondary text-accent-3">
          Sync messages with Sales Enablement tools
        </p>
        <p class="mt-0 font-small-3 text-secondary text-darken-3">
          Send your LinkedIn conversations to your connected Sales Enablement tools to keep track of your outreach activities.
        </p>
      </b-col>

      <b-col
        md="4"
        class="text-center"
      >
        <b-form-select
          v-model="account.settings.syncConvToSalesEnablement"
          :disabled="!account.isAdmin"
          :options="optSyncConvToSalesEnablement"
          class="mb-1"
          @input="updateAccount"
        />
      </b-col>
    </b-row>

    <b-row
      class="my-8"
    >
      <b-col md="10">
        <p class="mb-3 font-small-3 font-weight-bolder text-secondary text-accent-3">
          Prevent non-admin users from editing CRM {{ titleProp }} mapping
        </p>
        <p class="mt-0 font-small-3 text-secondary text-darken-3">
          Only admin users will have the authority to edit CRM {{ titleProp }} mapping in
          your dashboard and the embedded {{ titleProp }} layout on your LinkedIn
          interface.
        </p>
      </b-col>

      <b-col
        md="2"
        class="text-center"
      >
        <b-form-checkbox
          v-model="account.settings.adminOnlyFieldMapping"
          switch
          class="mb-1"
          :disabled="!account.isAdmin"
          @input="updateAccount('adminOnlyFieldMapping')"
        />
      </b-col>
    </b-row>

    <section v-if="account.crm !== 'GSHEETS'">
      <p class="mb-3 font-small-3 font-weight-bolder text-secondary text-accent-3">
        Custom Deal name
      </p>
      <b-form>
        <b-form-group>
          <b-form-input
            id="newDealInput"
            v-model="account.settings.contactDealName"
            :disabled="!account.isAdmin"
            placeholder="New deal with #contact of #company"
            class="mb-1"
            @change="updateAccount('contactDealName')"
          />

          <p class="mt-3 font-small-3 text-secondary text-darken-3">
            Change the default template for your new deals.
            <b-badge
              pill
              variant="light-success"
            >
              #contact
            </b-badge>,
            <b-badge
              pill
              variant="light-success"
            >
              #firstname
            </b-badge>,
            <b-badge
              pill
              variant="light-success"
            >
              #lastname
            </b-badge>
            and
            <b-badge
              pill
              variant="light-success"
            >
              #company
            </b-badge>
            will be replaced with the actual deal's contact and company name.
          </p>
        </b-form-group>
      </b-form>
    </section>

    <p class="mt-3 mb-0 font-small-3 text-secondary text-darken-3">
      These settings apply to all your team members using Surfe.
    </p>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  VBTooltip,
  BRow,
  BCol,
  BBadge,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { trackEvent } from '@core/utils/tracking'
import settingMapJson from './settingsMap.json'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BBadge,
    BFormSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      optSyncConvToSalesEnablement: [
        { value: '', text: 'Off' },
        { value: 'notes', text: 'Sync with notes' },
      ],
      optReadOnlySalesEnablement: [
        { value: true, text: 'Read only' },
        { value: false, text: 'Full access' },
      ],
      settingsMap: settingMapJson,
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      featureFlags: state => state.api.featureFlags,
      titleProp: state => (state.api.account.crm === 'HUBSPOT' ? 'properties' : 'fields'),
    }),
  },
  methods: {
    getSettingsMap() {
      return Object.keys(this.settingsMap)
        .filter(x => this.settingsMap[x].crm.includes(this.account.crm))
        .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = this.settingsMap[key]
          return obj
        }, {})
    },
    isBehindFeatureFlag(key) {
      if (!this.settingsMap[key].featureFlag || !this.featureFlags.flags) return false

      return this.featureFlags.flags[this.settingsMap[key].featureFlag]
        && !this.featureFlags.flags[this.settingsMap[key].featureFlag].value
    },
    async updateAccount(key) {
      try {
        await this.$store.dispatch('api/updateAccount', this.account)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Account settings saved',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
        await trackEvent({ type: 'dashboard-settings-feature_toggled', props: { feature: key, isEnabled: this.account.settings[key] } })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        await this.$store.dispatch('api/loadAccount')
        throw error
      }
    },
  },
}
</script>
