<template>
  <div>
    <!-- Enabled features -->
    <b-card
      title="Features"
      class="p-2"
    >
      <b-row>
        <b-col
          v-for="key in Object.keys(getFeaturesMap())"
          :key="key"
          :class="isBehindFeatureFlag(key) ? 'd-none' : ''"
          md="6"
        >
          <b-form
            v-if="featuresMap[key] && !isBehindFeatureFlag(key)"
            class="mb-3"
          >
            <b-form-checkbox
              v-if="hasBasicPlan && featuresMap[key].legacyBasicUsers"
              switch
              :disabled="true"
            >
              {{ featuresMap[key].title }}
              <b-badge
                variant="light-success"
                pill
              >
                Essential
              </b-badge>
              <feather-icon
                v-b-tooltip.hover.bottom="`Upgrade your plan to use this feature`"
                style="margin-left: 8px;"
                variant="outline-primary"
                size="14"
                icon="InfoIcon"
                class="text-muted"
              />
            </b-form-checkbox>
            <b-form-checkbox
              v-else
              v-model="account.features[key]"
              switch
              :disabled="!account.isAdmin || isDisabled(key)"
              @input="updateAccount"
            >
              {{ featuresMap[key].title }}
              <b-badge
                v-if="account.crm === 'GSHEETS' && !featuresMap[key].crm.includes(account.crm)"
                variant="light-info"
                pill
              >
                Connect CRM
              </b-badge>
              <b-badge
                v-else-if="featuresMap[key].pillTitle"
                variant="light-success"
                pill
              >
                {{ featuresMap[key].pillTitle }}
              </b-badge>
              <b-badge
                v-else-if="featuresMap[key].limitedPill && !account.hasEnterprise && !account.hasBusiness"
                variant="light-info"
                pill
              >
                Limited
              </b-badge>
              <feather-icon
                v-if="featuresMap[key].description"
                v-b-tooltip.hover.bottom="featuresMap[key].description"
                style="margin-left: 8px;"
                variant="outline-primary"
                size="14"
                icon="InfoIcon"
                class="text-muted"
              />
            </b-form-checkbox>
          </b-form>
        </b-col>

        <b-col md="6">
          <b-form
            v-if="templateAnalyticsFlag"
            class="mb-3"
          >
            <b-form-checkbox
              v-model="account.features.enableTemplateAnalytics"
              switch
              :disabled="account.crm === 'GSHEETS' || hasBasicPlan"
              @input="updateAccount"
            >
              Template Analytics
              <b-badge
                v-if="hasBasicPlan"
                variant="light-success"
                pill
              >
                Essential
              </b-badge>
              <b-badge
                v-if="account.crm === 'GSHEETS'"
                variant="light-info"
                pill
              >
                Connect CRM
              </b-badge>
              <feather-icon
                v-if="account.crm !== 'GSHEETS'"
                v-b-tooltip.hover.bottom="
                  hasProPlan ?
                    'Get insights about your LinkedIn message templates (reply rate, usage, reply delay, ...)' :
                    'Upgrade your plan to use this feature'
                "
                style="margin-left: 8px;"
                variant="outline-primary"
                size="14"
                icon="InfoIcon"
                class="text-muted"
              />
            </b-form-checkbox>
          </b-form>

        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="Admin Privileges"
      class="p-2"
    >
      <b-row>
        <b-col
          v-for="key in Object.keys(getAdminOnlyFeaturesMap())"
          :key="key"
          md="6"
        >
          <b-form
            v-if="adminOnlyFeaturesMap[key]"
            class="mb-3"
          >
            <b-form-checkbox
              v-model="account.features[key]"
              switch
              :disabled="!account.isAdmin"
              @input="updateAccount"
            >
              {{ adminOnlyFeaturesMap[key].title }}
              <feather-icon
                v-if="adminOnlyFeaturesMap[key].description"
                v-b-tooltip.hover.bottom="adminOnlyFeaturesMap[key].description"
                style="margin-left: 8px;"
                variant="outline-primary"
                size="14"
                icon="InfoIcon"
                class="text-muted"
              />
            </b-form-checkbox>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BFormCheckbox, BCol, BRow, BBadge, VBTooltip,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import featuresMapJson from './featuresMap.json'
import adminOnlyFeaturesMapJson from './adminOnlyFeaturesMap.json'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BForm,
    BFormCheckbox,
    BCol,
    BRow,
    BBadge,
  },
  data() {
    const templateAnalyticsFlag = process.env.VUE_APP_TEMPLATE_USER_ANALYTICS_ENABLED === 'true'
    return {
      templateAnalyticsFlag,
      updatingAccount: false,
      featuresMap: featuresMapJson,
      adminOnlyFeaturesMap: adminOnlyFeaturesMapJson,
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      featureFlags: state => state.api.featureFlags,
      hasBasicPlan: state => {
        const companyPlan = state.api.account?.companyPlan
        const accountPlan = state.api.account?.accountPlan
        return companyPlan === 'BASIC' || accountPlan === 'BASIC'
      },
      hasProPlan: state => {
        const companyPlan = state.api.account?.companyPlan
        return companyPlan === 'PRO'
      },
    }),
  },
  async mounted() {
    await Promise.all([this.$store.dispatch('api/loadFeatureFlags'), this.$store.dispatch('api/loadUserStatus')])
  },
  methods: {
    isBehindFeatureFlag(key) {
      if (!this.featuresMap[key].featureFlag || !this.featureFlags.flags) return false

      return this.featureFlags.flags[this.featuresMap[key].featureFlag]
          && !this.featureFlags.flags[this.featuresMap[key].featureFlag].value
    },
    isDisabled(key) {
      if (!this.featuresMap[key].crm.includes(this.account.crm)) return true
      if (key === 'enableMobilePhoneEnrichment') return !this.account.features.enableEnrichment
      return false
    },
    getFeaturesMap() {
      return Object.keys(this.featuresMap).reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = this.featuresMap[key]
        return obj
      }, {})
    },
    getAdminOnlyFeaturesMap() {
      return Object.keys(this.adminOnlyFeaturesMap).reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = this.adminOnlyFeaturesMap[key]
        return obj
      }, {})
    },
    async updateAccount() {
      if (this.updatingAccount) return

      try {
        this.updatingAccount = true

        if (!this.account.features.enableEnrichment) {
          this.account.features.enableMobilePhoneEnrichment = false
        }

        await this.$store.dispatch('api/updateAccount', this.account)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Account settings saved',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        await this.$store.dispatch('api/loadAccount')
        throw error
      } finally {
        this.updatingAccount = false
      }
    },
  },
}
</script>
<style scoped>
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }
</style>
