<template>
  <b-tabs
    class="mx-auto p-1"
    style="max-width:1100px"
    nav-class="nav-justified"
  >
    <!-- General -->
    <b-tab :active="activeTab === 'settings'">
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-2"
        />
        <span class="font-weight-bold">Admin Settings</span>
      </template>

      <account-general />
    </b-tab>

    <!-- Enabled features -->
    <b-tab :active="activeTab === 'features'">
      <template #title>
        <feather-icon
          icon="CheckSquareIcon"
          size="18"
          class="mr-2"
        />
        <span class="font-weight-bold">Enable Features</span>
      </template>

      <account-features />
    </b-tab>

    <!-- Danger Zone -->
    <b-tab
      v-if="account.isAdmin"
      :active="activeTab === 'danger-zone'"
    >
      <template #title>
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
          class="mr-2"
        />
        <span class="font-weight-bold">Danger Zone</span>
      </template>

      <account-danger />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { mapState } from 'vuex'
import AccountGeneral from './components/AccountGeneral.vue'
import AccountFeatures from './components/AccountFeatures.vue'
import AccountDanger from './components/AccountDanger.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountGeneral,
    AccountFeatures,
    AccountDanger,
  },
  data() {
    return {
      options: {},
      activeTab: 'settings',
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
    }),
  },
  async mounted() {
    this.activeTab = this.$route.query.activeTab ?? 'settings'
    await this.$store.dispatch('api/loadAccount')
  },
}
</script>
